let countrySelected="";
//7days
let expireTime = new Date(new Date().getTime() + 60000 * 60 * 24 * 7);

function validateFormModal() {
  let x = $("#country").val()
  countrySelected=$("#country").find(':selected').attr('data-country');
  console.log(countrySelected);
  if (x == "") {
    $("#country").css('border-color', "#f45a5d");
    return false;
  }
  else if(x=="allowed"){
    Cookies.set('countrySelected', countrySelected, { expires: expireTime });
      // $(".step-one").hide();
      // $(".step-two").show();
      $("#next-btn-ghost").trigger("click");
      // ScrollTrigger.refresh();
      stepTwo.showModal(); //show everyone except restricted
      
    //   if(countrySelected=="ksa")
    //   {
    //     stepTwo.showModal()
    //   }
    //   else if(countrySelected=="others"){
    //     stepTwoB.showModal()
    //   }
      

     // $()

  }
  else{
    //   $(".step-one").hide();
    //   $(".restricted").show();
    $("#next-btn-ghost").trigger("click");
    // ScrollTrigger.refresh();
    restricted.showModal();
  }
  
}


document.addEventListener("DOMContentLoaded", function () { 
    
  
  $("#agree-btn-yes").on("click",function(){
      //$('.modal-disclaimer').remove(); // close modal
        console.log('agree-btn-yes clicked')
        $("#agree-btn-yes-ghost").trigger("click");
        // ScrollTrigger.refresh();
      
      // Set this cookie
      Cookies.set('notice', 'shown', { expires: expireTime });       
       if(countrySelected=='others'){
          //$(".forksa").css('display','none');
          //$(".forksa").remove();
          console.log("hide for ksa, ksa not selected");
       }
       else{
        //$(".forothers").css('display','none');
         //$(".forothers").remove();
          console.log("hide for others, ksa is selected");
       }
  })

  $("#agree-not-btn").on("click",function(){
    //   $(".step-two").hide();
    //   $(".restricted").show();
    $("#agree-not-btn-ghost").trigger("click");
    // ScrollTrigger.refresh();
    restricted.showModal();
  });

  

  $("#agree-not-btnTwo").on("click",function(){
    //   $(".step-two").hide();
    //   $(".restricted").show();
    $("#agree-not-btn-ghostTwo").trigger("click");
    // ScrollTrigger.refresh();
    restrictedTwo.showModal();
  });

  
  })

  $("#next-btn").on("click",function(){
    validateFormModal();
    console.log("click next on modal")
    });
  

    
$(".forothers").on("click",function(){

    //console.log(countrySelected);

    

    if(Cookies.get('countrySelected')=='others'){
        //$(".forksa").css('display','none');
        //$(".forksa").remove();
        console.log("hide for ksa, ksa not selected");
        stepTwoB.showModal(); //second disclaimer
     }
     else{
      //$(".forothers").css('display','none');
       //$(".forothers").remove();
        console.log("hide for others, ksa is selected");
        
        const fileUrl1 = "/documents/int_prospectus.pdf"; // Replace with your desired file URL
        const fileName = "intl_prospectus.pdf"; // Replace with your desired file name
        downloadFile(fileUrl1, fileName);
     }
    
})

//dont forget to update this to support AR PDF
$(".agree-download:lang(en)").on("click", function(){
    
    const fileUrl = "/documents/int_prospectus.pdf"; // Replace with your desired file URL
    const fileName = "intl_prospectus.pdf"; // Replace with your desired file name
    downloadFile(fileUrl, fileName);

    $("#agree-btn-yes-ghostTwo").trigger("click");

})

$(".agree-download:lang(ar)").on("click", function(){
    
    const fileUrl = "/documents/int_prospectus_ar.pdf"; // Replace with your desired file URL
    const fileName = "int_prospectus_ar.pdf"; // Replace with your desired file name
    downloadFile(fileUrl, fileName);

    $("#agree-btn-yes-ghostTwo").trigger("click");

})


function downloadFile(url, fileName) {
    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName; // File name

    // Append the link to the DOM and click it programmatically
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
}

// document.getElementById("downloadButton").addEventListener("click", function() {
//     const fileUrl = "https://www.example.com/example.txt"; // Replace with your desired file URL
//     const fileName = "example.txt"; // Replace with your desired file name

//     downloadFile(fileUrl, fileName);
// });



